import './App.css';
import image1 from './assets/hami-dan-aldy.png'
import bismillah from './assets/bismillah.png'
import mempelai from './assets/mempelai.png'
import image2 from './assets/image-2.png'

const App = () => {
  const RenderTime = () => {
    const queryParams = new URLSearchParams(window.location.search)
    const key = queryParams.get("key")
    let waktu;
    if(!key){
      return null;
    } else if(key === 'aa9ac5be') {
      waktu = 'Akad: 09.00 - 10.00'
    }else if(key === 'b5d4814'){
      waktu = 'Waktu: 11.00 - 12.00'
    } else if(key === 'c1254349d') {
      waktu = 'Waktu: 13.00 - 14.00'
    } else {
      return null;
    }
    return (
      <div className='centered text small-margin' id='wedding-time'>{waktu}</div>
    )
  }

  const handleButtonClick = () => {
    const a = document.getElementById('maps-link')
    if(a){
      a.click()
    }
  }

  return (
    <div className='App'>
      <div className='page-container' id='container-1'>
        <div className='content-container' id='first-content'>
          <img src={image1} alt='Hami & Aldy' id='hero-text-image'/>
        </div>
      </div>
      <div className='page-container' id='container-2'>
        <div className='content-container' id='second-content'>
          <img src={bismillah} alt='' id='bismillah'/>
          <div style={{padding: '5%'}}>
            <div className='centered text small-margin'>Assalamu'alaikum Warahmatullahi Wabarakatuh</div>
            <div className='centered text small-margin'>Dengan Memohon Rahmat Dan Ridho Allah SWT, </div>
            <div className='centered text small-margin'>Mohon Doa Restu Bapak/Ibu/Saudara/Saudari</div>
            <div className='centered text small-margin'>Untuk Pernikahan Putra-Putri Kami</div>
          </div>
          <img src={mempelai} alt='' id='mempelai'/>
        </div>
      </div>
      <div className='page-container' id='container-3'>
        <div className='content-container' id='third-content'>
          <div style={{padding: '5%'}}>
            <div className='centered text medium-margin'>Yang Insya Allah Akan Dilaksanakan Pada:</div>
            <div className='centered text small-margin' id='wedding-day'>Minggu, 07 Agustus 2022</div>
            <RenderTime />
          </div>
          <img src={image2} alt='' id='middle-image'/>
          <div style={{padding: '5%'}}>
            <div className='centered text medium-margin'>Bertempat di:</div>
            <div className='centered text small-margin' id='location-1'>NUSA INDONESIA GASTRONOMY (LOCARASA)</div>
            <div className='centered text small-margin'>Jl. Kemang Raya No. 81, Kemang, Jakarta Selatan</div>
          </div>
        </div>
      </div>
      <div className='page-container' id='container-4'>
        <div className='content-container' id='fourth-content'>
          <iframe title='maps' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.958707977461!2d106.8134985150713!3d-6.269161063118447!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f188710ee927%3A0x1de7d51549e9187d!2sNusa%20Indonesian%20Gastronomy!5e0!3m2!1sen!2sid!4v1659282219673!5m2!1sen!2sid" style={{border:0, height: 300}} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          <div id='maps-button' onClick={handleButtonClick}>Buka di Google Maps</div>
          <a href='https://www.google.com/maps?ll=-6.269166,106.815687&z=16&t=m&hl=en&gl=ID&mapclient=embed&cid=2154925234131114109' target='_blank' rel="noreferrer" style={{display:'none'}} id='maps-link'>maps</a>
          <div style={{padding: '5%'}}>
            <div className='centered text small-margin'>Atas Kehadiran & Doa Restu Bapak/Ibu/Saudara</div>
            <div className='centered text small-margin'>Kami Ucapkan Terimakasih</div>
            <div className='centered text small-margin'>Wassalamu'alaikum Warahmatullahi Wabarakatuh</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
